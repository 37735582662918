import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getQuestion, startSession, answerQuestion } from "../services/courses";
import Completed from "./Completed";
import CompletedCourse from "./CompletedCourse";
import Question from "./Question";

export default function SessionCourse(props) {
  const levels = {
    new: "New / Missed",
    level_1: "Level 1",
    level_2: "Level 2",
    level_3: "Level 3",
    trl: "Level Recall",
  };
  const [inSession, setInSession] = useState(false);
  const [level, setLevel] = useState();
  const [question, setQuestion] = useState();
  const [responses, setResponses] = useState({ questions: 0, correct: 0 });
  const [questionsAvailable, setQuestionsAvailable] = useState();

  const checkAnswer = (answer) => {
    answerQuestion(
      props.match.params.course,
      question.question_id,
      answer ?? ""
    )
      .then((response) => {
        // TODO: Capitalize answer if first word of concept [❌]

        let responseAnswerFormatted = response.data.answer;
        if (question.question[0] !== "_" && question.question.includes("_")) {
          // Find last character before _
          let q = question.question.split("_")[0].trim();

          if (![".", "?"].includes(q[q.length - 1])) {
            responseAnswerFormatted = responseAnswerFormatted
              .toString()
              .toLowerCase(); // Set to lowercase
          }
        }
        // Add answer to question
        let questionWithAns = question.question
          .replace("_", responseAnswerFormatted)
          .replace(/_/g, "");
        questionWithAns =
          questionWithAns === question.question
            ? `${questionWithAns} ${response.data.answer}`
            : questionWithAns;

        setQuestion({
          ...question,
          question: questionWithAns,
          answer: { ...response.data },
        });
        setResponses({
          ...responses,
          questions: responses.questions + 1,
          correct: responses.correct + response.data.correct,
        });
      })

      .catch((error) => {
        alert("Error " + JSON.stringify(error));
      });
  };
  const getQuestionUI = () => {
    getQuestion(props.match.params.course)
      .then((response) => setQuestion(response.data))
      .catch((error) => {
        if (error.response.data === "No questions available") startSessionUI();
      });
  };

  const startSessionUI = () => {
    startSession(props.match.params.course)
      .then((response) => {
        setInSession(true);
        if (response.data === "no_questions") {
          setQuestionsAvailable(() => false);
          return;
        } else if (response.data === "trl") {
          setQuestionsAvailable(() => false);
          setLevel(() => "trl");
          return;
        }
        setQuestionsAvailable(() => true);
        setLevel(levels[response.data]);
        getQuestionUI();
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };
  useEffect(() => {
    // Starts a new session
    startSessionUI();
  }, []);
  return (
    <div className="container">
      {!inSession && <Spinner animation="border" />}
      {inSession && questionsAvailable && question && (
        <Question
          question={question}
          checkAnswer={checkAnswer}
          getQuestion={getQuestionUI}
          level={level}
          responses={responses}
        />
      )}
      {inSession && !questionsAvailable && level !== "trl" && (
        <div> - No more questions available for today</div>
      )}
      {level == "trl" && <CompletedCourse course={props.match.params.course} />}
    </div>
  );
}
