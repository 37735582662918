import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Figure,
  Form,
  Spinner,
} from "react-bootstrap";

export default function Question(props) {
  const [prevAnswer, setPrevAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [audioUrl, setAudioUrl] = useState(false);
  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
    setLoading(false);
  }, [props.question]);

  useEffect(() => {
    setAudioUrl(
      question.answer ? question.answer.answer_audio : question.question_audio
    );
  }, [question]);

  const audioElement = useRef(null);
  const inputElement = useRef(null);
  const nextQuestion = useRef(null);

  const submitAnswer = () => {
    if (loading) return;
    nextQuestion.current.focus();
    setPrevAnswer(answer);
    let currAns = answer ?? "";
    if (!question.answer) props.checkAnswer(currAns.trim());
    else {
      props.getQuestion();
      setAnswer("");
    }

    setLoading(true);
  };

  const playAudio = () => {
    audioElement.current.play();
  };

  return (
    <Container>
      <div className="row">
        {autoplay && (
          <audio autoPlay={{ autoplay }} src={audioUrl} ref={audioElement} />
        )}
        {!autoplay && <audio src={audioUrl} ref={audioElement} />}

        <div className="col-md-4">
          <div className="row">
            <Figure>
              <Figure.Image src={question.question_img} />
              <Figure.Caption>{question.additional_text}</Figure.Caption>
            </Figure>
          </div>
          <div className="row">
            <p>
              Level: {props.level} <br />
              Responses: {props.responses.questions} <br />
              Correct: {props.responses.correct}
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="pb-5">
            <p>
              <h4>{question.question}</h4>
            </p>
          </div>
          <div className="pt-5">
            {question.answer && question.answer.correct && (
              <h3>{question.answer.answer} ✅</h3>
            )}
            {question.answer && !question.answer.correct && (
              <>
                <p>
                  Correct Answer: <strong>{question.answer.answer} ✅</strong>
                </p>
                {answer !== "" && (
                  <p>
                    Your Answer: <strong>{prevAnswer}</strong> ❌
                  </p>
                )}
              </>
            )}

            {!question.answer && (
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Enter Answer"
                  ref={inputElement}
                  onChange={(e) => setAnswer(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (!question.answer) submitAnswer();
                      else props.getQuestion();
                    }
                  }}
                  autoFocus
                />
              </Form.Group>
            )}
          </div>
          <div>
            <ButtonGroup aria-label="Basic example" className="p-2">
              <Button
                variant="secondary"
                onClick={() => setAutoplay(!autoplay)}
              >
                Autoplay {autoplay && "🔈"}
                {!autoplay && "🔇"}
              </Button>
            </ButtonGroup>
            <ButtonGroup aria-label="Basic example" className="p-2">
              <Button variant="secondary" onClick={playAudio}>
                Play Audio ►
              </Button>
            </ButtonGroup>
            <ButtonGroup aria-label="Basic example">
              <Button
                variant="secondary"
                onClick={submitAnswer}
                ref={nextQuestion}
              >
                {question.answer && !loading && "Next question"}
                {!question.answer && !loading && "Check"}
                {loading && <Spinner animation="border" size="sm" />}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </Container>
  );
}
