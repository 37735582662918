import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Certificate(props) {
  const [certId, setCertId] = useState();

  useEffect(() => {
    setCertId(props.match.params.certId);
  }, []);

  return (
    <Container>
      <Document
        file={`https://cors-anywhere.herokuapp.com/https://cert.recalldev.xyz/${props.match.params.certId}.pdf`}
      >
        <Page pageNumber={1} />
      </Document>
    </Container>
  );
}
