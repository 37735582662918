import Carousel from "./Carousel";
import graph_1 from "../img/home/homeGraph.png";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Carousel />
      <div className="container">
        <hr className="featurette-divider" />
        <div className="row featurette">
          <div className="col-md-7 d-flex align-items-center">
            <div className="align-middle">
              <h2 className="featurette-heading">
                The problem. <span className="text-muted">The solution.</span>
              </h2>
              <p className="lead">
                With traditional learning 80% of learned material is forgotten —
                a 20% retention rate.{" "}
              </p>
              <p className="lead">
                Recall Learning AI literally “learns” what each user forgets and
                automatically guides a user to achieve permanent measurable
                recall rates in excess of 90%; an increase of 350% over
                traditional learning.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <img src={graph_1} alt="Graph" className="img-fluid" />
          </div>
        </div>
      </div>
      <hr />

      <footer className="page-footer font-small mdb-color pt-4">
        <div className="container text-center text-md-left">
          <div className="row text-center text-md-left mt-3 pb-3">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Recall Learning
              </h6>
              <p>
                Recall learning system is focused on the first three levels of
                Bloom’s Taxonomy: knowledge, comprehension, and application,
                which build the gateway skills for learning any subject.
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
              <p>
                <a href="#!">Learning system</a>
              </p>
              <p>
                <Link to="/courses">Courses</Link>
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Useful links
              </h6>
              <p>
                <a href="#!">Your Account</a>
              </p>
              <p>
                <a href="#!">Business Partners</a>
              </p>
              <p>
                <a href="#!">Help</a>
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
              <p>🏢 San Diego, CA, 92001, US</p>
              <p>📧 hello@recalllearning.com</p>
              <p>📞 + 01 234 567 88</p>
              <p>📞 + 01 234 567 89</p>
            </div>
          </div>

          <hr />

          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-12">
              <p className="text-center text-md-center">
                © 2020 Copyright:
                <a href="https://recalllearning.com/">
                  <strong> recalllearning.com</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
