import { BASE_URL } from "../constants/main";
import { getCredentials } from "./auth";
const axios = require("axios");
const instance = axios.create({
  baseURL: BASE_URL,
  headers: { "Access-Control-Allow-Origin": "*" },
});

const getCategories = () => {
  return instance.post("/getCourse", {
    get: "categories",
  });
};

const getCourses = () => {
  return instance.post("/getCourse", {
    get: "courses",
  });
};
const getCourseDetails = (courseName) => {
  return instance.post("/getCourse", {
    get: "course",
    name: courseName,
  });
};
const enroll = (course_name) => {
  return instance.post(
    "/enroll",
    {
      course_name,
    },
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
const startSession = (course_name) => {
  return instance.post(
    "/startSession",
    {
      course_name,
    },
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
const getQuestion = (course_name) => {
  return instance.post(
    "/getQuestion",
    {
      course_name,
    },
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
const answerQuestion = (course_name, question_id, answer) => {
  console.log(course_name, question_id, answer);
  return instance.post(
    "/answerQuestion",
    {
      course_name,
      question_id,
      answer,
    },
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
const getProgress = (course_name) => {
  return instance.post(
    "/getProgress",
    { course_name },
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
export {
  getCategories,
  getCourses,
  getCourseDetails,
  enroll,
  startSession,
  getQuestion,
  answerQuestion,
  getProgress,
};
