import { BASE_URL } from "../constants/main";
import { getCredentials } from "./auth";
const axios = require("axios");
const instance = axios.create({
  baseURL: BASE_URL,
  headers: { "Access-Control-Allow-Origin": "*" },
});

const userLogin = ({ email, password }) => {
  return instance.post("/login", {
    email,
    password,
  });
};

const userSignUp = ({
  email,
  password,
  firstName,
  lastName,
  birthdate,
  invitationCode,
}) => {
  return instance.post("/signup", {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    birthdate,
    invitation_code: invitationCode,
  });
};
const getUser = () => {
  return instance.post(
    "/getUserInfo",
    {},
    { headers: { Authorization: `Bearer ${getCredentials()}` } }
  );
};
export { userLogin, userSignUp, getUser };
