import { useState, useEffect, useRef } from "react";
import {
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Container,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getCategories } from "../services/courses";

export default function Menu() {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [current, setCurrent] = useState("");
  const [loading, setLoading] = useState(true);
  const dropMenu = useRef(null);
  useEffect(() => {
    getCategories().then((response) => {
      setCategories(response.data);
      setLoading(false);
    });
  }, []);

  const pushTo = () => {
    history.push(
      `/courses/${
        dropMenu.current.value !== "all" ? dropMenu.current.value : ""
      }`
    );
  };

  return (
    !loading && (
      <Container>
        <Navbar bg="light" variant="light">
          <Nav className="mr-auto">
            <Nav.Link>
              <Form.Control
                as="select"
                onChange={(e) => pushTo()}
                ref={dropMenu}
              >
                <option value="all">All Categories</option>
                {categories.map((x) => (
                  <option value={x.uri}>{x.category}</option>
                ))}
              </Form.Control>
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
    )
  );
}
