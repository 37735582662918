import { useState, useEffect } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { getCourses } from "../services/courses";
import CourseDetails from "./CourseDetails";
import ListCourses from "./ListCourses";
import Menu from "./Menu";

export default function Courses(props) {
  const category = props.match.params.category ?? null;
  const courseName = props.match.params.course ?? null;
  console.log(courseName);
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    getCourses().then((response) => setCourseList(response.data));
  }, []);

  return (
    <Container>
      {!courseName && (
        <div className="col-md-12">
          <Menu />
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          {courseName && <CourseDetails course={courseName} />}
          {!courseName && (
            <ListCourses courses={courseList} category={category} />
          )}
        </div>
      </div>
    </Container>
  );
}
