import { Button, Container, Jumbotron, Tab, Tabs } from "react-bootstrap";
import Completed from "./Completed";
import InProgress from "./InProgress";

export default function Lobby() {
  return (
    <>
      <Jumbotron fluid className="lobbyHead">
        <Container>
          <h1 className="welcomeMessage">Welcome</h1>
        </Container>
      </Jumbotron>
      <div className="container">
        <Tabs defaultActiveKey="inProgress" id="uncontrolled-tab-example">
          <Tab eventKey="inProgress" title="My Courses">
            <InProgress />
          </Tab>
          <Tab eventKey="completed" title="Completed">
            <Completed />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
