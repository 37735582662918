import { Badge, Container, Table, Col, Row, Button } from "react-bootstrap";

import { useState, useEffect } from "react";
import { getProgress } from "../services/courses";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  ReferenceLine,
  Area,
} from "recharts";
import { getUserInfo } from "../services/auth";

export default function Progress(props) {
  const [progress, setProgress] = useState("");
  const [studyTime, setStudyTime] = useState(0);
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    getProgress(props.match.params.course).then((response) => {
      setProgress(response.data);
      setGraphData([
        {
          name: "New",
          Retention: (response.data.level_new.retention_rate * 100).toFixed(1),
          Questions: response.data.level_new.questions,
        },
        {
          name: "Level 1",
          Retention: (response.data.level_1.retention_rate * 100).toFixed(1),
          Questions: response.data.level_1.questions,
        },
        {
          name: "Level 2",
          Retention: (response.data.level_2.retention_rate * 100).toFixed(1),
          Questions: response.data.level_2.questions,
        },
        {
          name: "Level 3",
          Retention: (response.data.level_3.retention_rate * 100).toFixed(1),
          Questions: response.data.level_3.questions,
        },
        {
          name: "Recall",
          //Retention: (response.data.level_trl.retention_rate * 100).toFixed(1),
          Questions: response.data.level_trl.questions,
        },
      ]);
    });
  }, []);

  useEffect(() => {
    setStudyTime(msToTime(progress.totalTime));
  }, progress.totalTime);

  const msToTime = (s) => {
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    return (
      (hrs > 0 ? `${hrs} ${hrs === 1 ? `hour` : `hours`} ` : "") +
      (mins > 0 ? `${mins} ${mins === 1 ? `minute` : `minutes`}` : "")
    );
  };

  return (
    <Container>
      {progress && (
        <>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h4>{progress.course_name} </h4>
                </Col>
                <Col className="text-right">
                  <Button className="custom_btn" onClick={() => window.print()}>
                    Print 🖨
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md>
                  Student: <strong>{getUserInfo().email}</strong>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md>
                  Total questions: <strong>{progress.totalQuestions}</strong>
                </Col>
                <Col md>
                  Overall completion:{" "}
                  <strong>
                    {(progress.overallCompletionRate * 100).toFixed(1)} %
                  </strong>
                </Col>
                <Col md>
                  {" "}
                  Total study time: <strong>{studyTime}</strong>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={5}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Questions</th>
                    <th>Retention Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>New / Missed</td>
                    <td>{progress.level_new.questions}</td>
                    <td>
                      {(progress.level_new.retention_rate * 100).toFixed(1)} %
                    </td>
                  </tr>
                  <tr>
                    <td>Level 1</td>
                    <td>{progress.level_1.questions}</td>
                    <td>
                      {(progress.level_1.retention_rate * 100).toFixed(1)} %
                    </td>
                  </tr>
                  <tr>
                    <td>Level 2</td>
                    <td>{progress.level_2.questions}</td>
                    <td>
                      {(progress.level_2.retention_rate * 100).toFixed(1)} %
                    </td>
                  </tr>
                  <tr>
                    <td>Level 3</td>
                    <td>{progress.level_3.questions}</td>
                    <td>
                      {(progress.level_3.retention_rate * 100).toFixed(1)} %
                    </td>
                  </tr>
                  <tr>
                    <td>Recall</td>
                    <td>{progress.level_trl.questions}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={7}>
              <p>
                <ResponsiveContainer width={"100%"} height={320}>
                  <BarChart
                    width={500}
                    height={300}
                    data={graphData}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      stroke="#0d5289"
                      type="number"
                      domain={[0, 100]}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      stroke="#0d5289"
                      type="number"
                      domain={[0, progress.totalQuestions]}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar
                      yAxisId="left"
                      name="Retention Rate %"
                      dataKey="Retention"
                      fill="#0d5289"
                    />
                    <Bar
                      yAxisId="right"
                      name="Num. of Questions"
                      dataKey="Questions"
                      fill="#19baee"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </p>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
