import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getUser } from "../services/user";
import LobbyCourseCard from "./LobbyCourseCard";
export default function Completed() {
  const [courses, setCourses] = useState();

  useEffect(() => {
    getUser().then((response) => {
      if (response.data.courses_details)
        setCourses(
          response.data.courses_details.filter((c) => c.status === "completed")
        );
      else setCourses([]);
    });
  }, []);

  return (
    <Container>
      <div className="row">
        <div className="scrolling-wrapper row flex-row  p-2">
          {courses &&
            courses.map((c) => (
              <>
                <LobbyCourseCard course={c} />
              </>
            ))}
          {courses && courses.length === 0 && (
            <p> You have not completed courses yet</p>
          )}
        </div>
      </div>
    </Container>
  );
}
