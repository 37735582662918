import "./App.css";
import Home from "./components/Home";
import Header from "./components/Header";
import Courses from "./components/Courses";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./styles/main.scss";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Logout from "./components/Logout";
import { loggedIn, getUserInfo } from "./services/auth";
import Lobby from "./components/Lobby";
import SessionCourse from "./components/SessionCourse";
import Progress from "./components/Progress";
import Certificate from "./components/Certificate";
import Demo from "./components/Demo";

function App() {
  document.title = "Reminisci - Learning";
  return (
    <Router>
      <Header />
      <main>
        <Switch>
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/courses/:category" component={Courses} />
          <Route path="/courses/:category/:course" component={Courses} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/join" component={SignUp} />
          <Route path="/demo" component={Demo} />
          <Route path="/session/:course" component={SessionCourse} />
          <Route exact path="/progress/:course" component={Progress} />
          <Route exact path="/cert/:certId" component={Certificate} />

          <Route path="/">
            {!loggedIn() && <Home />}
            {loggedIn() && <Lobby />}
          </Route>
        </Switch>
      </main>
    </Router>
  );
}

export default App;
