import { useState } from "react";
import { Link } from "react-router-dom";
import { loggedIn, getUserInfo } from "../services/auth";
import { Dropdown, DropdownButton } from "react-bootstrap";
import logo from "../img/home/reminisciLogo.png";

export default function Header() {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-white">
      <a className="navbar-brand font-newtown text-purple" href="/">
        {/* <h2>Recall Learning</h2> */}
        <img
          src={logo}
          alt=""
          className="img-fluid"
          style={{ width: "200px" }}
        />
      </a>

      <button
        className="navbar-toggler text-purple bg-purple"
        type="button"
        data-toggle="collapse"
        data-target="#toggle-nav"
        aria-controls="#toggle-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="toggle-nav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active" key={"home"}>
            <Link to="/">
              <button className="btn  text-purple">Home</button>
            </Link>
          </li>{" "}
          <li className="nav-item active" key={"catalog"}>
            <Link to="/courses">
              <button className="btn  text-purple">All Courses</button>
            </Link>
          </li>{" "}
          <li className="nav-item active" key={"reports"}>
            {loggedIn() && (
              <Link to="/courses">
                <button className="btn  text-purple">Reports</button>
              </Link>
            )}
          </li>
        </ul>
        {!loggedIn() && (
          <>
            <div>
              <Link to="/login">
                <button className="btn text-purple"> Log in</button>
              </Link>
            </div>
            <div>
              <Link to="/join">
                <button className="btn bg-purple text-white"> Join Now!</button>
              </Link>
            </div>
          </>
        )}
        {loggedIn() && (
          <>
            <div>
              <DropdownButton
                className="custom_btn"
                id="user_dropdown"
                title="My account"
              >
                <Dropdown.ItemText>{getUserInfo().email}</Dropdown.ItemText>
                <Dropdown.Divider />
                <Dropdown.Item href="/">Home</Dropdown.Item>
                <Dropdown.Item href="/logout">Log Out</Dropdown.Item>
              </DropdownButton>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
