import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getProgress } from "../services/courses";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

export default function CompletedCourse(props) {
  const [progress, setProgress] = useState({});

  useEffect(() => {
    getProgress(props.course).then((response) => {
      setProgress(response.data);
    });
  }, []);

  const msToTime = (s) => {
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    return (
      (hrs > 0 ? `${hrs} ${hrs === 1 ? `hour` : `hours`} ` : "") +
      (mins > 0 ? `${mins} ${mins === 1 ? `minute` : `minutes`}` : "")
    );
  };

  const downloadCert = (e) => {
    e.preventDefault();
    window.location = `https://cert.recalldev.xyz/${progress.certificate_status}.pdf`;
  };
  const { width, height } = useWindowSize();
  return (
    <>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={200}
        gravity={0.06}
      ></Confetti>
      <Container>
        <Row className="pt-5">
          <Col className="text-center">
            <h3>Congratulations!!</h3>
            <p>
              You have completed the course <span>{progress.course_name}</span>
            </p>
            <p>Total completion time: {msToTime(progress.totalTime)}</p>
            <hr />
            <p>
              {progress.certificate_status === "processing" && (
                <span>
                  We are processing your certificate, you will receive an email
                  as soon as it's ready
                </span>
              )}
              {progress.certificate_status &&
                progress.certificate_status !== "processing" && (
                  <Button onClick={downloadCert} className="custom_btn">
                    Download certificate
                  </Button>
                )}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
