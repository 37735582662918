import { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { userSignUp } from "../services/user";

export default function SignUp() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordCopy, setPasswordCopy] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState();
  const [invitationCode, setInvitationCode] = useState();
  const [inProcess, setInProcess] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [registrationErrorMsg, setRegistrationErrorMsg] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setInProcess(true);
    setRegistrationError(false);

    if (password !== passwordCopy) {
      setRegistrationError(true);
      setRegistrationErrorMsg("Password don't match");
      setInProcess(false);
      return;
    }
    if (firstName === "" || lastName === "") {
      setRegistrationError(true);
      setRegistrationErrorMsg("First and last name are required");
      setInProcess(false);
      return;
    }
    // Over 13 years old

    if (
      new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() -
        1970 <
      13
    ) {
      setRegistrationError(true);
      setRegistrationErrorMsg("Invalid birthdate (must be over 13 years old)");
      setInProcess(false);
      return;
    }

    if (invitationCode === "") {
      setRegistrationError(true);
      setRegistrationErrorMsg("Invitation code is required");
      setInProcess(false);
      return;
    }
    userSignUp({
      email,
      password,
      firstName,
      lastName,
      birthdate,
      invitationCode,
    })
      .then((response) => {
        setRegistrationSuccess(true);
      })
      .catch((error) => {
        setRegistrationError(true);
        setInProcess(false);
        setRegistrationErrorMsg(error.response.data);
      });
  };
  return (
    <div className="container">
      {registrationSuccess && (
        <p>User created, please click on Log In to start your session</p>
      )}
      {!registrationSuccess && (
        <>
          <Alert key="betaAlert" variant="warning">
            Recall Learning registration is currently open by invitation only,
            therefore an invitation code is required to join.
          </Alert>
          <Form onSubmit={handleSubmit}>
            {registrationError && (
              <Alert key="loginError" variant="danger">
                {registrationErrorMsg}
              </Alert>
            )}
            <Form.Group controlId="basicInfo">
              <Form.Label>Basic Information</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <br />
              <Form.Control
                type="text"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
              <br />
              <Form.Control
                type="date"
                placeholder="Birthdate (mm/dd/yyyy)"
                onChange={(e) => setBirthdate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Control
                type="password"
                placeholder="Repeat password"
                onChange={(e) => setPasswordCopy(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Invitation code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your invitation code"
                onChange={(e) => setInvitationCode(e.target.value)}
              />
            </Form.Group>
            {!inProcess && (
              <Button variant="primary" className="custom_btn" type="submit">
                Join
              </Button>
            )}
            {inProcess && (
              <Button variant="primary" className="custom_btn" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Join
              </Button>
            )}
          </Form>
        </>
      )}
    </div>
  );
}
