import slide1 from "../img/home/office-620822_1280.jpg";
import slide2 from "../img/home/notebook-336634_1280.jpg";
import slide3 from "../img/home/girl-5662435_1280.jpg";
import { Link } from "react-router-dom";

const slides = [
  {
    url: slide1,
    title: "Courses",
    text:
      "Recall Learning technology is ideally suited as a study guide for almost any type of subject material",
    align: "left",
    button: "View Courses",
    linkUrl: "/courses",
  },
  {
    url: slide2,
    title: "Learning system",
    text:
      "Imagine your organization’s success if your employees achieved 90% long term memory retention.",
    align: "center",
  },
  {
    url: slide3,
    title: "Recall Learning",
    text:
      "The learning system is focused on the first three levels of Bloom’s Taxonomy: knowledge, comprehension, and application, which build the gateway skills for learning any subject.",
    align: "right",
    button: "Join Now!",
    linkUrl: "/join",
  },
];

export default function () {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">
        {slides.map((slide, i) => {
          return (
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to={i}
              className={i === 0 ? "active" : ""}
              key={i}
            ></li>
          );
        })}
      </ol>
      <div className="carousel-inner">
        {slides.map((slide, i) => {
          return (
            <div
              key={`slide${i}`}
              className={i === 0 ? "carousel-item active" : "carousel-item"}
            >
              <img className="d-block w-100" src={slide.url} alt="Slide" />
              <div
                className={`carousel-caption d-none d-md-block text-${slide.align}`}
              >
                <h1 className="mb-2">
                  <span className="bg-purple p-2 ">{slide.title}</span>
                </h1>
                <br />
                <p>
                  <span className="bg-black-50 p-2">{slide.text}</span>
                </p>
                {slide.button ? (
                  <p>
                    <Link to={slide.linkUrl}>
                      <a
                        className="btn btn-md bg-purple text-white"
                        href="#"
                        role="button"
                      >
                        {slide.button}
                      </a>
                    </Link>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}
