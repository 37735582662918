import { useState, useEffect } from "react";

import CourseCard from "./CourseCard";

export default function ListCourses(props) {
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    if (props.category) {
      setCourseList(props.courses.filter((x) => x.category === props.category));
    } else setCourseList(props.courses);
  }, [props.category, props.courses]);

  return (
    <div className="row container text-center">
      <div className="scrolling-wrapper row flex-row  ">
        {courseList.map((course) => (
          <>
            <CourseCard course={course} />
          </>
        ))}
      </div>
    </div>
  );
}
