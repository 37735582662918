import jwt_decode from "jwt-decode";

const loggedIn = () => {
  return localStorage.getItem("Auth") ? true : false;
};
const getCredentials = () => {
  return localStorage.getItem("Auth");
};
const logIn = (token) => {
  // Store token
  localStorage.setItem("Auth", token);
};
const logOut = () => {
  localStorage.clear();
};
const getUserInfo = () => {
  return jwt_decode(getCredentials());
};
export { loggedIn, getCredentials, logIn, logOut, getUserInfo };
