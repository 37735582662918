import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCourseDetails } from "../services/courses";

export default function LobbyCourseCard(props) {
  const [course, setCourse] = useState();

  useEffect(() => {
    setCourse(props.course);
  }, []);

  // useEffect(() => {
  //   getCourseDetails(props.courseName).then((response) => {
  //     setCourse(response.data);
  //   });
  // }, []);

  return (
    <div className="p-2 text-center">
      {!course && (
        <Card style={{ width: "12rem" }}>
          <Card.Body>
            <Spinner animation="border" />
          </Card.Body>
        </Card>
      )}
      {course && (
        <Card style={{ width: "12rem" }}>
          <Card.Img variant="top" src={course.card_image} />
          <Card.Body className="mx-auto">
            <Card.Title className="mx-auto">{course.course_title}</Card.Title>
            <Card.Text className="mx-auto">
              {/* {course.course_description} */}
            </Card.Text>
            <Link to={`/session/${course.course_name}`} className="mx-auto">
              <Button variant="primary" className="custom_btn mx-auto">
                {course.status === "in_progress"
                  ? "Start Learning"
                  : "View certificate"}
              </Button>
            </Link>
            <Link to={`/progress/${course.course_name}`} className="mx-auto">
              Check progress
            </Link>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
