import { useState, useEffect } from "react";
import { enroll, getCourseDetails } from "../services/courses";
import { Accordion, Card, Button, Spinner, Form } from "react-bootstrap";
import { getUser } from "../services/user";
import { loggedIn } from "../services/auth";

export default function CourseDetails(props) {
  const [course, setCourse] = useState({});
  const [enrolled, setEnrolled] = useState(false);
  const [enrollProcess, setEnrollProcess] = useState(false);
  const isloggedIn = loggedIn();

  useEffect(() => {
    if (isloggedIn) {
      getUser().then((response) => {
        setEnrolled(response.data.courses.includes(props.course));
        getCourseDetails(props.course).then((response) => {
          setCourse(response.data);
        });
      });
    } else {
      getCourseDetails(props.course).then((response) => {
        setCourse(response.data);
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEnrollProcess(true);
    enroll(props.course).then((response) => {
      setEnrollProcess(false);
      setEnrolled(true);
    });
  };
  return (
    <div>
      {!course.chapters && (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {course.chapters && (
        <>
          <div
            className="course_details_bg"
            style={{ backgroundImage: "url(" + course.background_image + ")" }}
          >
            <div className="course_title">
              <h2>{course.course_title}</h2>
            </div>
          </div>
          <hr></hr>
          <p>{course.course_description}</p>

          {enrolled && isloggedIn && (
            <Button variant="success" disabled>
              Active Course ✓
            </Button>
          )}
          {!enrolled && !enrollProcess && isloggedIn && (
            <Form onSubmit={handleSubmit}>
              <Button className="custom_btn" type="submit">
                Activate Course
              </Button>
            </Form>
          )}
          {!enrolled && enrollProcess && (
            <Form onSubmit={handleSubmit}>
              <Button className="custom_btn" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Activating...
              </Button>
            </Form>
          )}

          <hr />
          <span>Chapters</span>
          <Accordion>
            {course.chapters.map(
              (c, i) => (
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={c.chapter_name}
                    >
                      {c.chapter_description}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={c.chapter_name}>
                    <Card.Body>{c.chapter_description}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ),
              0
            )}
          </Accordion>
        </>
      )}
    </div>
  );
}
