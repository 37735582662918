import { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { userLogin } from "../services/user";
import { logIn } from "../services/auth";

export default function Demo() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [inProcess, setInProcess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  useEffect(() => {
    setLoginError(false);
  }, [email, password]);

  useEffect(() => {
    setEmail("demo@demo.com");
    setPassword("demodemo");
    userLogin({ email, password })
      .then((response) => {
        logIn(response.data);
        window.location = "/";
        setInProcess(false);
      })
      .catch((error) => {
        setLoginError(true);
        setInProcess(false);
      });
  });
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setInProcess(true);
  //   setLoginError(false);
  //   userLogin({ email, password })
  //     .then((response) => {
  //       logIn(response.data);
  //       window.location = "/";
  //       setInProcess(false);
  //     })
  //     .catch((error) => {
  //       setLoginError(true);
  //       setInProcess(false);
  //     });
  // };
  return null;
  // return (
  //   <div className="container">
  //     <Form onSubmit={handleSubmit}>
  //       {loginError && (
  //         <Alert key="loginError" variant="danger">
  //           Incorrect Email and/or password!
  //         </Alert>
  //       )}
  //       <Form.Group controlId="formBasicEmail">
  //         <Form.Label>Email address</Form.Label>
  //         <Form.Control
  //           type="email"
  //           placeholder="Enter email"
  //           onChange={(e) => setEmail(e.target.value)}
  //         />
  //       </Form.Group>
  //       <Form.Group controlId="formBasicPassword">
  //         <Form.Label>Password</Form.Label>
  //         <Form.Control
  //           type="password"
  //           placeholder="Password"
  //           onChange={(e) => setPassword(e.target.value)}
  //         />
  //       </Form.Group>
  //       {!inProcess && (
  //         <Button variant="primary" type="submit" className="custom_btn">
  //           Submit
  //         </Button>
  //       )}
  //       {inProcess && (
  //         <Button variant="primary" disabled className="custom_btn">
  //           <Spinner
  //             as="span"
  //             animation="grow"
  //             size="sm"
  //             role="status"
  //             aria-hidden="true"
  //           />{" "}
  //           Submit
  //         </Button>
  //       )}
  //     </Form>
  //   </div>
  // );
}
