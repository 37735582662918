import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CourseCard(props) {
  return (
    <Card style={{ width: "11rem" }} className="m-2 text-center">
      <Link
        to={`/courses/${props.course.category + "/" + props.course.uri}`}
        className="mx-auto"
      >
        <Card.Img variant="top" src={props.course.card_image} />
        <Card.Body>
          <Card.Title>{props.course.course}</Card.Title>
          {/* <Card.Text className="mx-auto">{props.course.description}</Card.Text> */}
        </Card.Body>
      </Link>
    </Card>
  );
}
